import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"
// import Banner from "../core/Banner"
import { segmentAction, useSegmentTrackLoad } from "../hooks/useSegmentTrack"
import GlobalStyles from "./GlobalStyles"
import "./layout.css"
import PageAbout from "./PageAbout"
import PageCallToAction from "./PageCallToAction"
import PageContact from "./PageContact"
import PageEnterprise from "./PageEnterprise"
import PageFeatures from "./PageFeatures"
import PageModalPlan from "./PageModalPlan"
import PageNav from "./PageNav"
import PageTestimonials from "./PageTestimonials"
import Pricing from "./Pricing"
import SignUp from "./SignUp"
import useShowHideModalContext from "./useShowHideModalContext"

const plans = [
  {
    name: "Site",
  },
  {
    name: "Scheduler",
  },
  {
    name: "Assistant",
  },
]

const Section = styled.section`
  :before,
  :after {
    content: "";
    clear: both;
    display: table;
  }
  line-height: 1.3;
  font-size: 1.25rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  h1 {
    font-size: 4.5rem;
    line-height: 0.95;
    margin-bottom: 0.5em;
  }
  p {
    margin-bottom: 0.75rem;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem;
    line-height: 1.4;
    h1 {
      font-size: 2.25rem;
      line-height: 0.95;
      margin-bottom: 0.5em;
    }
  }
  ${({ dark }) => {
    if (dark)
      return `
        background: var(--sparkle-drkblue);
        color: #fff;
    `
  }}
`

const Content = styled.div`
  padding: 8rem var(--content-pad);
  max-width: 900px;
  margin: auto;
  @media screen and (max-width: 900px) {
    padding: 3rem var(--content-pad);
  }
`

const BackBtn = styled.button`
  font-size: 0rem;
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 45px;
  background: #fff;
  opacity: 0.75;
  border-radius: 100%;
  left: var(--content-pad);
  top: var(--content-pad);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Page = () => {
  useSegmentTrackLoad("Homepage")
  const [modal, setModal] = useShowHideModalContext()
  const [plan, setPlan] = useState(0)
  const [showPlanOpts, setShowPlanOpts] = useState(true)
  const [pricingModel, setPricingModel] = useState(true)
  const [monthly, setMonthly] = useState(false)
  const showFeatureCallout = true

  return (
    <>
      <ThemeProvider theme={{ color: { accent: "#3790E2" } }}>
        <GlobalStyles />
        <PageNav
          links={[
            { name: "Features", href: "/pricing#features" },
            { name: "Pricing", href: "/pricing#top" },
            { name: "Our Story", href: "/about" },
            { name: "Contact", href: "/contact" },
          ]}
        />

        {/* <Banner /> */}
        <Section>
          <Content>
            <PageCallToAction />
          </Content>
        </Section>
        <Section dark id={"features"}>
          <Content style={{ position: "relative", overflow: "visible" }}>
            <StaticImage
              placeholder={"none"}
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                opacity: 1,
                transform: "translateY(-25%)",
                background: "transparent",
              }}
              imgStyle={{
                width: 539,
                height: 355,
                background: "transparent",
              }}
              alt={""}
              src={"./imgs/sparkle-cluster.png"}
            />
            <PageFeatures />
          </Content>
        </Section>
        <Section dark>
          <Content>
            <h1>Join the crowd</h1>
            <PageTestimonials />
            <PageEnterprise />
          </Content>
        </Section>
        <Section id={"pricing"} dark>
          <Content>
            <Pricing
              showFeatureCallout={showFeatureCallout}
              setPricingModel={setPricingModel}
              pricingModel={pricingModel}
              monthly={monthly}
              setMonthly={setMonthly}
              onChange={(i) => {
                setPlan(i)
                setModal(true)
              }}
            />
          </Content>
        </Section>
        <Section dark id={"ourstory"}>
          <Content>
            <h1>Our story</h1>
            <PageAbout />
          </Content>
        </Section>
        <Section id={"follow-for-more"} as={"footer"} dark>
          <Content style={{ padding: "var(--content-pad)" }}>
            <PageContact />
          </Content>
        </Section>
        {/* Pricing + Url modal */}
        <PageModalPlan show={modal} onClose={() => setModal(false)}>
          <Section dark style={{ width: "100%" }}>
            <Content style={{ position: "relative" }}>
              <BackBtn
                onClick={() => {
                  segmentAction.track("Homepage Url Modal Close")
                  setModal(false)
                }}
              >
                {/* Backtick arrow */}
                <svg
                  viewBox="0 0 100 100"
                  style={{
                    fill: "var(--sparkle-drkblue)",
                    width: 36,
                    transform: "translateX(-7.5%)",
                    height: "auto",
                  }}
                >
                  <path d="M66.43,20a4,4,0,0,0-5.66,0L33.58,47.17a4,4,0,0,0,0,5.66L60.77,80a4,4,0,1,0,5.65-5.66L42.06,50,66.42,25.66A4,4,0,0,0,66.43,20Z"></path>
                </svg>
              </BackBtn>
              <h2 style={{ marginTop: "2rem", marginBottom: ".5rem" }}>
                Your site
              </h2>
              <SignUp
                btnText={"Continue"}
                plan={plan}
                pricingModel={pricingModel}
                monthly={monthly}
                // WHERE WE GO TO PRICING OR MAKING THE SITE
              />
              {showPlanOpts && (
                <Pricing
                  setPricingModel={setPricingModel}
                  pricingModel={pricingModel}
                  monthly={monthly}
                  setMonthly={setMonthly}
                  selected={plan}
                  noBtns // cleaner less actions
                  onChange={(i) => setPlan(i)}
                  noHeading
                  planName={plans[plan].name}
                />
              )}
            </Content>
          </Section>
        </PageModalPlan>
      </ThemeProvider>
    </>
  )
}

export default Page
