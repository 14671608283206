import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { segmentAction } from "../hooks/useSegmentTrack"

const Container = styled.div`
  h2 {
    color: #fff !important;
    text-transform: uppercase;
    margin-top: 3rem;
    font-size: 1rem;
    margin-bottom: 4rem;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    font-weight: normal !important;
    ::after,
    ::before {
      content: "";
      flex: 1;
      opacity: 0.4;
      height: 1px;
      background: #fff;
    }
    @media screen and (max-width: 900px) {
      margin-bottom: 1.5rem;
      font-size: 0.8rem;
    }
  }
`
const LogoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-items: center;
  gap: 2rem;
`
const LogoLink = styled.span`
  align-self: center;
  padding: 1rem;
  transition: 0.2s;
  &:hover {
    transform: translateY(-5px);
  }
  img {
    width: 100%;
    height: auto;
    max-height: 100px;
    object-fit: contain;
  }
`
const clients = [
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/build-from-within-alliance-logo.png"
        className={"img"}
        alt={"Build from Within Alliance Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://www.bfwalliance.org/",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/co-motion-logo.png"
        className={"img"}
        alt={"Co-Motion Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://www.comotioncenter.com/",
  },  
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/the-coven-logo.png"
        className={"img"}
        alt={"The Coven Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://www.thecoven.com/",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/greater-msp-logo.png"
        className={"img"}
        alt={"Greater MSP Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://www.greatermsp.org/",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/junior-achievement-logo.png"
        className={"img"}
        alt={"Junior Achievement Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://www.janorth.org/",
  },
  ,
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/launch-minnesota-logo.png"
        className={"img"}
        alt={"Launch Minnesota Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://mn.gov/launchmn/",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/meet-minneapolis-logo.png"
        className={"img"}
        alt={"Meet Minneapolis Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://www.minneapolis.org/",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/neighborhood-development-center-logo.png"
        className={"img"}
        alt={"Neighborhood Development Center Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://www.ndc-mn.org/",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/social-enterprise-msp-logo.png"
        className={"img"}
        alt={"Social Enterprise MSP Logo"}
        imgStyle={{ objectFit: 'contain' }}
      />
    ),
    url: "https://socialenterprisemsp.org/",
  }
]

const PageEnterprise = () => {
  return (
    <Container>
      <h2>We Sparkle Used by</h2>
      <LogoContainer>
        {clients.map(({img, url}, i) => {
          return (
            <LogoLink 
            key={i}
            as={"a"}
            href={url}
            target={"_blank"}
            onClick={() =>
              segmentAction.track("Homepage Enterprise Click", { value: url })
            }
            >
            {img}
            </LogoLink>
          )
        })}
      </LogoContainer>
    </Container>
  )
}

export default PageEnterprise
