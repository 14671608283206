import React from "react"
import styled, { keyframes } from "styled-components"

import SignUp from "./SignUp"
// import IFrames from "./IFrames"
import FeaturedCarousel from "./FeaturedCarousel"

import IconSparkle from "./icons/IconSparkle"

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: stretch;
  justify-content: center;
  max-width: 95vw;

  .sparkles {
    display: none;
  }

  @media screen and (max-width: 900px) and (min-width: 700px) {
    h1 {
      font-size: 3.5rem;
    }
    .carousel {
      transform: scale(0.75);
      margin-bottom: 4.5rem;
    }
  }

  @media screen and (max-width: 699px) {
    h1 {
      font-size: 3rem;
      padding-right: 0.5rem;
    }
    p {
      font-size: 1.2rem;
    }
    .carousel {
      display: none;
    }
    .sparkles {
      display: flex;
      justify-content: "space-between";
      align-items: "center";
      position: relative;
      transform: scale(0.8);
    }
  }
`

const twinkle = keyframes`
  from {
    opacity: .3;
    transform: scale(.7) translateZ(0);
  }
  to {
    opacity: 1;
    transform: scale(1) translateZ(0);
  }
`

const Sparkle = styled(IconSparkle)`
  transform: translateZ(0);
  animation: 1.25s ${twinkle} both alternate infinite;
`

const sparklePlacement = [
  {
    animationDelay: "900ms",
    position: "absolute",
    fill: "var(--sparkle-blue)",
    left: 20,
    top: 35,
    width: 90,
    height: "auto",
  },
  {
    animationDelay: "200ms",
    position: "absolute",
    fill: "var(--sparkle-green)",
    left: 15,
    // top: -60,
    width: 42,
    height: "auto",
  },
  {
    animationDelay: "600ms",
    position: "absolute",
    fill: "var(--sparkle-red)",
    left: 80,
    top: 10,
    width: 40,
    height: "auto",
  },
]

const PageCallToAction = () => {
  return (
    <>
      <Container>
        <div style={{ flex: 1, maxWidth: "95vw" }}>
          <div style={{ display: "flex" }}>
            <h1>
              Let your
              <br />
              <span>business</span>
              <br />
              <span style={{ color: "var(--sparkle-red)" }}>shine</span>
            </h1>
            <div className={"sparkles"}>
              {sparklePlacement.map((style, i) => (
                <Sparkle style={style} key={i} />
              ))}
            </div>
          </div>
          {/* <p>Make your business official.</p> */}

          <p>
            Effortlessly create a site you can share that grows with your
            business.
          </p>
          <p>
            Add links, downloads, products, accept tips, and schedule
            appointments!
          </p>
        </div>
        {/*
        <div className={"iframes"} style={{ flex: 1 }}>
        <IFrames />
        </div>
      */}
        <div className={"carousel"} style={{ flex: 1, maxWidth: "55vw" }}>
          <FeaturedCarousel />
        </div>
      </Container>
      <p
        style={{
          marginTop: "2rem",
          marginBottom: ".5rem",
          paddingTop: "1rem",
          letterSpacing: ".01em",
          fontWeight: "bold",
        }}
      >
        Claim your free site
      </p>
      <SignUp autoFocus />
    </>
  )
}

export default PageCallToAction
