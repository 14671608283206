import React from "react"
import styled from "styled-components"
import SignUp from "./SignUp"
import { segmentAction } from "../hooks/useSegmentTrack"

const feats = [
  {
    color: "var(--sparkle-yellow)",
    name: "Site",
    intro: "Share content, get paid for products and services.",
    bullets: ["Links", "Downloads", "Tips", "Products", "Free Forever!"],
    img: (
      <div style={{ padding: "1vw", flex: 1 }} className={"img"}>
        <img
          src={"/site.svg"}
          style={{ width: "100%", height: "auto" }}
          alt={""}
        />
      </div>
    ),
  },
  {
    color: "var(--sparkle-green)",
    name: "Scheduler",
    intro: (
      <>
        Grow your business
        <br />
        with bookings.
      </>
    ),
    bullets: [
      "Schedule individual or group sessions",
      "Offer free sessions or collect payments",
      "Encourage reviews once appointment is complete",
      "Easily schedule product pickup",
    ],
    paras: [],
    img: (
      <div
        style={{ padding: "3vw", flex: 1, position: "relative" }}
        className={"img"}
      >
        <img
          src={"/bookings.svg"}
          style={{ width: "100%", height: "auto" }}
          alt={""}
        />
      </div>
    ),
  },
  {
    color: "var(--sparkle-brightblue)",
    name: "Assistant",
    bullets: [
      "Automatically answer FAQs",
      "Upsell products or services",
      "Schedule over SMS",
    ],
    paras: ["Available on Facebook Messenger and text message (SMS)"],
    img: (
      <div style={{ padding: "1.75vw", flex: 1 }} className={"img"}>
        <img
          src={"/sms.svg"}
          style={{ width: "100%", height: "auto" }}
          alt={""}
        />
      </div>
    ),
    intro: (
      <>
        Save time and
        <br />
        increase revenues!
      </>
    ),
  },
]

const SignUpContainer = styled.div`
  margin: 5rem 0 0;
  h2 {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }
`

const Feature = styled.article`
  margin-bottom: 2.5rem;
  .text {
    flex: 350px 0 0;
  }
  .inner {
    align-items: center;
    display: flex;
    font-size: 1.2rem;
    ul {
      margin: 2rem 0;
    }
    li {
      display: flex;
      line-height: 1.4;
      margin-bottom: 0.75rem;
    }
    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    h3 {
      font-size: 1.3rem;
      line-height: 1.3;
      letter-spacing: 0.03em;
      margin-bottom: 1rem;
      font-weight: normal;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      .text {
        order: 4;
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .img {
      max-width: 400px;
      margin: 0rem 0 1rem;
    }
    .text {
      width: 100%;
    }
    .inner {
      align-items: flex-start;
    }
  }
  svg {
    @media screen and (max-width: 600px) {
      max-width: 400px;
      margin-bottom: 1rem;
    }
  }
`
export const Link = styled.a`
  display: block;
  margin-top: 3rem;
  font-weight: bold;
  color: white;
  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
`

const PageFeatures = () => {
  return (
    <>
      <h1>Features</h1>
      {feats.map(({ name, color, intro, bullets = [], paras = [], img }, i) => {
        return (
          <Feature
            key={i}
            style={{ marginBottom: i === feats.length - 1 ? 0 : "" }}
          >
            <div className={"inner"}>
              <div className={"text"}>
                <h2 style={{ color }}>{name}</h2>
                <h3>{intro}</h3>
                <ul>
                  {bullets.map((item, j) => {
                    return (
                      <li key={j}>
                        <span style={{ marginRight: 5, color }}>✓</span>
                        {item}
                      </li>
                    )
                  })}
                </ul>
                {paras.map((text, k) => {
                  return <p key={k}>{text}</p>
                })}
              </div>
              {img}
            </div>
          </Feature>
        )
      })}
      {/* <Link
      href="/pricing#features"
      target={"_blank"}
      onClick={() => {
          segmentAction.track(`Features Homepage Click`)
      }}
      className="show-feature-list">View Full Feature List →</Link> */}
      <SignUpContainer
      // style={{ marginBottom: i === feats.length - 1 ? 0 : "" }}
      >
        <h2>Claim your free site</h2>
        <SignUp />
      </SignUpContainer>
    </>
  )
}

export default PageFeatures
