import React from "react"
import styled, { keyframes } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import IconSparkle from "./icons/IconSparkle"

const testimonials = [
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/andrea-jones.png"
        className={"img"}
        alt={"Andrea Jones"}
      />
    ),
    headline: "Increase Sales",
    quote:
      "I had 4 people use the booking link this week since I added it to my signature!",
    name: "Andrea Jones",
    company: "Great Pursuits MN",
    color: "var(--sparkle-green)",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/danielle-steer.png"
        className={"img"}
        alt={"Danielle Steer"}
      />
    ),
    headline: "Save Time",
    quote: "We Sparkle saves me 4 hours a week!",
    name: "Danielle Steer",
    company: "Lunar Startups",
    color: "var(--sparkle-blue)",
  },
  {
    img: (
      <StaticImage
        placeholder={"none"}
        src="./imgs/sabrina-jones.png"
        className={"img"}
        alt={"Sabrina D. Jones"}
      />
    ),
    headline: "Sparkle",
    quote: "If I had known about We Sparkle from the beginning, I would have saved a lot of time and money.",
    name: "Sabrina D. Jones",
    company: "SJC Body Love",
    color: "var(--sparkle-red)",
  },
]

const twinkle = keyframes`
  from {
    opacity: .3;
    transform: scale(.7) translateZ(0);
  }
  to {
    opacity: 1;
    transform: scale(1) translateZ(0);
  }
`

const Sparkle = styled(IconSparkle)`
  position: absolute;
  height: auto;
  width: 11%;
  transform: translateZ(0);
  animation: 1.5s ${twinkle} both alternate infinite;
  @media screen and (max-width: 500px) {
    display: none;
  }
`
const Container = styled.div`
  margin: 4rem 0 2rem;
  @media screen and (max-width: 900px) {
    margin: 2rem 0;
  }
`

const Testimonial = styled.article`
  display: flex;
  margin-bottom: 2rem;

  .img {
    flex-shrink: 0;
    width: min(50vw, 225px);
    height: min(50vw, 225px);
    border-radius: 100%;
  }
  .text-container {
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .headline {
    color: var(--sparkle-yellow);
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  .quote {
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    line-height: 1.5;
    margin-bottom: 0rem;
  }
  .name {
    margin-bottom: 0;
    font-family: Kristi;
    font-size: clamp(46px, 4vw, 1rem);
  }
  .company {
    font-size: 1.2rem;
    color: var(--sparkle-blue);
  }
  @media screen and (max-width: 500px) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    text-align: center;
    .text-container {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`

const PageTestimonials = () => {
  return (
    <Container>
      {testimonials.map(({ img, quote, name, headline, company, color }, i) => {
        return (
          <Testimonial key={i}>
            <div style={{ position: "relative" }}>
              {img}
              <Sparkle
                style={{
                  animationDelay: `${0 - i * 300}ms`,
                  fill: "#fff",
                  right: "-4.4%",
                  top: "-4.4%",
                }}
              />
              <Sparkle
                style={{
                  animationDelay: `${500 - i * 300}ms`,
                  fill: color,
                  right: "8.8%",
                  top: "4.4%",
                }}
              />
              <Sparkle
                style={{
                  animationDelay: `${1000 - i * 300}ms`,
                  fill: "var(--sparkle-yellow)",
                  right: 0,
                  top: "17.6%",
                }}
              />
            </div>
            <div className={"text-container"}>
              <p className={"headline"}>{headline}</p>
              <p className={"quote"}>"{quote}"</p>
              <p className={"name"}>{name}</p>
              <p className={"company"}>{company}</p>
            </div>
          </Testimonial>
        )
      })}
    </Container>
  )
}

export default PageTestimonials
