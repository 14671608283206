import React from "react"
import styled, { keyframes } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonPrimary } from "../homepage/Buttons"
import { segmentAction } from "../hooks/useSegmentTrack"
import { Link } from "gatsby"

const Container = styled.div`
  .circle {
    position: relative;
    float: left;
    width: 360px;
    height: 360px;
    margin-left: -20px;
    margin-top: -20px;
    shape-outside: circle(180px);
    @media screen and (max-width: 900px) {
      width: calc(40vw + 30px);
      height: calc(40vw + 30px);
      margin-left: -15px;
      margin-top: -15px;
      shape-outside: circle(calc(20vw + 15px));
    }
  }
  .img {
    /* background: green; */
    position: absolute;
    width: 320px;
    height: 320px;
    border-radius: 100%;
    left: 20px;
    top: 20px;
    @media screen and (max-width: 900px) {
      left: 20px;
      top: 20px;
      width: 40vw;
      height: 40vw;
      border-radius: 100%;
    }
  }
  .ceo {
    position: absolute;
    bottom: 0%;
    left: 20px;
    font-family: Kristi !important;
    font-size: 3rem;
    margin-bottom: 0;
    line-height: 0.75;
    text-shadow: 0 1px 3px black;
    span {
      font-family: Kristi !important;
    }
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  p {
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 900px) {
    p {
      font-size: 1rem;
    }
  }
`
const shift = keyframes`
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(10px);
  }
`
const ButtonArrowMovement = styled.span`
  font-family: "Tahoma";
  margin-left: 10px;
`
const CTA = styled.div`
  display: flex;
  justify-content; center;
`
const Button = styled(ButtonPrimary)`
  margin-left: 0.5em;
  font-size: 1.25rem;
  display: inline-flex;
  align-items: center;
  :hover ${ButtonArrowMovement} {
    animation: 0.6s ease-in-out ${shift} infinite both alternate;
  }
  @media screen and (max-width: 900px) {
    font-size: 1.2rem;
    width: 100%;
    justify-content: center;
    margin-left: 0;
    padding-left: 0;
    margin-top: 0.5rem;
    padding-right: 0;
    :hover ${ButtonArrowMovement} {
      animation: none;
    }
  }
`

const PageAbout = () => {
  return (
    <Container>
      <div className={"circle"}>
        <div className={"img"}>
          <StaticImage
            placeholder={"none"}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
            }}
            src="./imgs/michelle-maryns-ceo-we-sparkle.png"
            alt={"Michelle Maryns | We Sparkle CEO"}
          />
        </div>
        <div className={"ceo"}>
          Michelle Maryns
          <br />
          <span style={{ color: "var(--sparkle-yellow)", fontSize: ".75em" }}>
            CEO We Sparkle
          </span>
        </div>
      </div>
      <div style={{ lineHeight: 1.55, letterSpacing: "0.01em" }}>
        <p>
          People who start small businesses contribute more creativity,
          innovation, and greater well-being to our communities—both
          economically and socially.
        </p>
        <p>
          We Sparkle is dedicated to building a stronger, more inclusive economy
          by equipping underestimated entrepreneurs with the tools they need to
          succeed.
        </p>
        <p>
          Inspired by her mother’s experiences, We Sparkle founder Michelle Tran
          Maryns became passionate about helping solopreneurs, sidepreneurs, and
          small business owners.
        </p>
        <p>
          Michelle's mom started a fabric business in Vietnam, but after moving
          to the United States as a refugee, she didn’t feel confident
          continuing her business. Michelle always wondered what her mom could
          have accomplished if she had.
        </p>
        <p>
          Michelle created We Sparkle to empower women like her mom and other
          underestimated entrepreneurs with simple tools to start and run their
          businesses with confidence.
        </p>
        <CTA>
          <Link                 
          to={"/about"}
          bg="var(--sparkle-red)"
          onClick={() => {
              segmentAction.track(`Homepage About Page Click`)
          }}
          > 
          <Button>            
            <span>Get to know our team</span>
            <ButtonArrowMovement> →</ButtonArrowMovement>
          </Button>
          </Link>
        </CTA>
      </div>
    </Container>
  )
}

export default PageAbout
